<template>
	<div class="container">
		<div class="flex-between headwarp">
			<div class="head-item" @click="goChooseClassfiy()">1.选择类别</div>
			<div class="head-item active">2.填写信息</div>
			<div class="head-item">3.完成发布</div>
		</div>
		<div class="cont-body">
			<div class="content">
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">类型<label class="bishu">*</label></div>
					</div>
					<div class="ctx-item-b">
						<el-radio-group v-model="transModel" class="radio_warp">
							<div v-for="(item,index) in carTransModels" v-if="item.key != '0' &&  item.key != '3'" :key="item.key" class="radio_itm_warp">
								<el-radio :label="item.key">{{item.value}}</el-radio>
							</div>
						</el-radio-group>
					</div>
				</div>
				<template v-if="transModel.startsWith('nkcor')">
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">名称<label class="bishu">*</label></div>
						</div>
						<div class="ctx-item-b" >
							<el-input placeholder="请输入名称" v-model="storeCorName" maxlength="30" />
						</div>
					</div>
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">地址<label class="bishu">*</label></div>
						</div>
						<div class="ctx-item-b" >
							<el-input placeholder="请输入地址" v-model="storeCorAddr" maxlength="80" />
						</div>
					</div>
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">简介<label class="bishu">*</label></div>
						</div>
						<div class="ctx-item-b">
							<el-input type="textarea" v-model="storeCorDesc" :autosize="{ minRows: 4}" maxlength="200" placeholder="请输入简介！" show-word-limit></el-input>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">面向地区<label class="bishu">*</label></div>
						</div>
						<div class="ctx-item-b">
							<el-cascader
							    v-model="areaId"
								:props="cascaderprops"
							    :options="citysList"
							    @change="handleCityChange">
							</el-cascader>
						</div>
					</div>
					<div v-if="transModel == '1' || transModel == '2'">
						<div class="ctx-item">
							<div class="ctx-item-t">
								<div class="ctx-item-head">车辆品牌<label class="bishu">*</label></div>
							</div>
							<div class="ctx-item-b" >
								<el-input placeholder="请输入车辆品牌" v-model="carBrand" maxlength="20"/>
							</div>
						</div>
						<div class="ctx-item">
							<div class="ctx-item-t">
								<div class="ctx-item-head">年份<label class="bishu">*</label></div>
							</div>
							<div class="ctx-item-b" >
								<el-input placeholder="请输入购买年份" v-model="buyYear" maxlength="4" type="digit"/>
							</div>
						</div>
						<div class="ctx-item">
							<div class="ctx-item-t">
								<div class="ctx-item-head">公里数<label class="bishu">*</label></div>
							</div>
							<div class="ctx-item-b" >
								<el-input placeholder="请输入公里数" v-model="kilometre" maxlength="10" type="digit"/>
							</div>
						</div>
						<div class="ctx-item">
							<div class="ctx-item-t">
								<div class="ctx-item-head">看车地址<label class="bishu">*</label></div>
							</div>
							<div class="ctx-item-b" >
								<el-input placeholder="请输入看车地址" v-model="lookAddr" maxlength="80" />
							</div>
						</div>
					</div>
					<div class="ctx-item" v-if="fyIndex == '1'">
						<div class="ctx-item-t">
							<div class="ctx-item-head">出价<label v-if="CurrencySymbol.length > 0">({{CurrencySymbol}})</label><label class="bishu">*</label></div>
							<div class="pub_coin_unit_desc">面向地区所在国家币种</div>
						</div>
						<div class="ctx-item-b flex-start" >
							<div class="hxs-warp">
								<el-input v-model="price" maxlength="8" placeholder="请输入价格" />
							</div>
							<div class="flex-start" v-if="transModel == '2' || transModel == '3'">
								<div class="margr10">/</div>
								<div class="hxs-warp">
									<el-select v-model="unitOfTransIndex" placeholder="请选择">
										<el-option
											v-for="item in array"
											:key="item.key"
											:label="item.value"
											:value="item.key">
										</el-option>
									</el-select>
								</div>
							</div>
						</div>
					</div>
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">详细说明<label class="bishu">*</label></div>
							<div class="ctx-item-headdesc" v-if="transModel == '0' || transModel == '3'">({{remark}})</div>
						</div>
						<div class="ctx-item-b">
							<el-input type="textarea" v-model="reqNail" :autosize="{ minRows: 4}" maxlength="200" placeholder="请输入内容" show-word-limit></el-input>
						</div>
					</div>
				</template>
				
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">是否置顶（30天）<label class="bishu">*</label></div>
					</div>
					<div class="ctx-item-b" style="text-align: right;">
						<el-switch
							v-model="tickTopOrNot"
							@change="tickTopChange"
							active-color="#13ce66"
							inactive-color="#ff4949">
						</el-switch>
					</div>
				</div>
				<div class="ctx-item inlinediv">
					<pickContact ref="pickContact" :queryUid="userInfo.fayanboId" :baseUrlpath="baseUrlpath" @changeConcactShow="changeConcactShow" :isShowConcact="isShowConcact" :isDetail="isDetail"></pickContact>
				</div>
				<div class="ctx-item" v-if="transModel == '1' || transModel == '2' || transModel.startsWith('nk')">
					<div class="ctx-item-t">
						<div class="ctx-item-head">上传照片/视频<label class="bishu">*</label></div>
						<div class="ctx-item-headdesc"><label>({{finalFileList.length}}/{{limitFilesNum}})</label></div>
					</div>
					<div class="ctx-item-b">
						<uploadFile :baseUrlpath="baseUrlpath" :fileList="fileList" @addOrMulitiFileList="addOrMulitiFileList"></uploadFile>
					</div>
				</div>
				<div class="ctx-item">
					<div class="ctx-item-b">
						<div class="pub_jd_cost">
							<div class="jd_total">
								<label class="unit">共需花费</label>
								<label class="num">{{pubTotalCost}}</label>
								<label class="unit">金豆</label>
							</div>
							<div class="cost_desc">
								<label>发布</label>
								<label class="num">{{pubNeedCost}}</label>
								<label>金豆</label>
								<template v-if="tickTopOrNot">
									<label>+</label>
									<label>置顶</label>
									<label class="num">{{tickTopNeedCost}}</label>
									<label>金豆</label>
								</template>
							</div>
						</div>
					</div>
				</div>
				<div class="btn-foot">
					<div class="btn" @click="publish()">确认发布</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapGetters,mapActions} from 'vuex'
	import uploadFile from '@/components/upload/uploadFile.vue'
	import pickContact from '@/components/pickContact.vue'
	import {queryUrl,doPost,commitFileUrl,queryUrlWithUInfo,postWithUInfo} from '@/api/index';
	import { Toast } from 'mint-ui';
	export default {
		components:{
		  uploadFile,pickContact
		},
		data(){
			return {
				reqType:'',
				cascaderprops:{
					value:'id',
					label:'name',
					children:'child'
				},
				carTransModels:[],
				transModel:'1',
				storeCorName:'',
				storeCorlatitude:'',
				storeCorlongitude:'',
				storeCorAddr:'',
				storeCorDesc:'',
				carBrand:'',
				buyYear:'',
				kilometre:'',
				fyIndex:1,
				price:'',
				areaId:'',
				reqNail:'',
				lookAddr:'',
				indicatorStyle: `height: 50px;`,
				remark:'想要什么样的车可用在这详细说明',
				maskStyle:``,
				fileList:[],
				finalFileList:[],
				limitFilesFlg:true,
				limitFilesNum:9,
				baseUrlpath:'',
				datetimesingle:'',
				dqList:[],
				array:[],
				unitOfTransIndex:'2',
				CurrencySymbol:'',
				isShowConcact:true,
				tickTopOrNot:false,
				tickTopNeedCost:0,
				pubNeedCost:0,
				pubTotalCost:0,
				isDetail:false,
				reqNo:'',
				sdr_userId:''
			}
		},
		created() {
			// console.log(this.$route.query)
			this.reqType = this.$route.query.reqType;
			this.array = this.$util.unitOfTransList;
			this.carTransModels = this.$util.carTransModel;
			this.citysList = this.$util.citysList;
			this.queryTickJdCost()
			this.queryPubJdCost()
		},
		computed: {
			...mapGetters(['userInfo'])
		},
		watch:{
			tickTopNeedCost:{
				handler(val, oldval) {
					if(this.tickTopOrNot){
						this.pubTotalCost = parseInt(val) + parseInt(this.pubNeedCost) 
					}else{
						this.pubTotalCost = parseInt(this.pubNeedCost)
					}
				}
			},
			pubNeedCost:{
				handler(val, oldval) {
					if(this.tickTopOrNot){
						this.pubTotalCost = parseInt(val) + parseInt(this.tickTopNeedCost) 
					}else{
						this.pubTotalCost = parseInt(val)
					}
				}
			},
			tickTopOrNot:{
				handler(val, oldval) {
					console.log(val)
					if(val){
						this.pubTotalCost = parseInt(this.pubNeedCost) + parseInt(this.tickTopNeedCost) 
					}else{
						this.pubTotalCost = parseInt(this.pubNeedCost)
					}
				}
			}
		},
		methods:{
			tickTopChange(e){
				var needCost = this.tickTopNeedCost;
				var _this = this;
				console.log(e)
				if(e){
					this.$confirm('置顶需花费'+needCost+'金豆'+', 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						
					}).catch(() => {
						_this.tickTopOrNot = false       
					});
				}
			},
			async queryTickJdCost(){
				const initres = await queryUrlWithUInfo('/apis/queryJdNeedCost',{queryType:'1'});
				if(initres.ec == '0'){
					this.tickTopNeedCost = parseInt( initres.jdCost)
				}
			},
			async queryPubJdCost(){
				const initres = await queryUrlWithUInfo('/apis/pubJdNeedCost',{queryType:'1'});
				if(initres.ec == '0'){
					this.pubNeedCost = parseInt(initres.jdCost)
				}
			},
			changeConcactShow(val){
				this.isShowConcact = val
			},
			goBack() {
				this.$util.goBack();
			},
			async publish(){
				var vm = this;
				if(this.transModel.startsWith('nk')){
					if(this.transModel.startsWith('nkcor')) {
						if(this.storeCorName.length == 0){
							this.$util.warnMsg('请输入名称');
							return false;
						}
						if(this.storeCorAddr.length == 0){
							this.$util.warnMsg('请输入地址');
							return false;
						}
						if(this.storeCorDesc.length == 0){
							this.$util.warnMsg('请输入简介');
							return false;
						}
					}
					if(this.finalFileList.length > 0){
						this.$util.warnMsg('请上传图片');
						return false;
					}
				}else{
					if('1' == this.transModel || '2' == this.transModel){
						if(this.carBrand.length == 0){
							this.$util.warnMsg('请输入车品牌');
							return false;
						}
						if(this.buyYear.length == 0){
							this.$util.warnMsg('请输入购买年份');
							return false;
						}
						if(this.kilometre.length == 0){
							this.$util.warnMsg('请输入公里数');
							return false;
						}
						if(this.lookAddr.length == 0){
							this.$util.warnMsg('请输入看车地址');
							return false;
						}
					}
								
					if(this.areaId.length == 0){
						this.$util.warnMsg('请选择面向地区');
						return false;
					}
					if(this.fyIndex == '1'){
						if(this.price.length == 0){
							this.$util.warnMsg('请输入价格');
							return false;
						}
					}
					if(this.reqNail.length == 0){
						this.$util.warnMsg('请输入详细说明');
						return false;
					}
				}
				
				vm.$util.showMyLoading('加载中');
				var params = {};
				params.reqType = "9";
				params.fyIndex = this.fyIndex;
				params.reqNail = this.$util.textFormat(this.reqNail);
				params.storeCorName = this.storeCorName;
				params.storeCorAddr = this.storeCorAddr;
				params.storeCorDesc = this.storeCorDesc;
				params.storeCorlatitude = this.storeCorlatitude;
				params.storeCorlongitude = this.storeCorlongitude;
				params.lookAddr = this.lookAddr;
				params.price = this.price;
				params.dqModel = JSON.stringify(this.areaId);
				params.fileList = JSON.stringify(this.finalFileList);
				params.kilometre = this.kilometre;
				params.buyYear = this.buyYear;
				params.carBrand = this.carBrand;
				params.transModel = this.transModel;
				params.unitOfTrans = this.unitOfTransIndex;
				params.isShowConcact = this.isShowConcact ? '1' : '0';
				params.tickTopOrNot = this.tickTopOrNot ? '1' : '0';
				const initres = await postWithUInfo('/apis/publishReqPost',params);
				if(initres.ec == '0'){
					vm.$util.hideMyLoading();
					this.$util.msg("发布成功",{},()=>{
						vm.$util.goBack();
					});
				}else{
					vm.$util.hideMyLoading();
					this.$util.msg("发布失败，请联系客服",{},()=>{
						
					});
				}
			},
			goChooseClassfiy(){
				this.$router.push({
				  name: 'publishIndex',
				  query: {}
				});
			},
			handleCityChange(e){
				// console.log(e)
				this.queryCurrencySymbol();
			},
			async queryCurrencySymbol(){
				const initres = await queryUrlWithUInfo('/apis/queryCurrencySymbol',{'dqModel':JSON.stringify(this.areaId)});
				if(initres.ec == '0'){
					this.CurrencySymbol = initres.currencySymbol;
				}
			},
			addOrMulitiFileList(params){
				// console.log(params)
				if(params.type == '1'){
					this.finalFileList.push({fileId:params.file.fileId,path:params.file.path,url:params.file.path,thumbPath:params.file.thumbPath})
					// console.log(this.fileList)
				}else if(params.type == '2'){
					this.finalFileList = this.finalFileList.filter((item,index) => {
						return item.fileId != params.file.fileId
					});
					// console.log(this.fileList)
				}
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.radio_warp {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		.radio_itm_warp {
			margin: 0px 10px 10px 0px;
		}
	}
	.container {
		width: 90%;
		margin: 0 auto;
		box-shadow: 0px 4px 16px 1px rgb(0 0 0 / 6%);
		height: 100%;
		padding: 10px 20px;
		.headwarp {
			.head-item {
				width: 30%;
				text-align: left;
				line-height: 50px;
				border-bottom: 2px solid #F1F1F1;
			}
			.active {
				border-bottom: 2px solid #C88854;
			}
		}
		.cont-body {
			margin-top: 20px;
			//overflow: scroll;
			min-height: calc(100% - 100px);
			.content {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.ctx-item {
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 10px 0;
					width: 600px;
					.bishu {
						color: #FF0000;
						margin-left: 4px;
					}
					.ctx-item-t {
						margin-right: 10px;
						.ctx-item-head {
							line-height: 40px;
							width: 160px;
							text-align: left;
							font-size: 14px;
							font-weight: 700;
						}
						.ctx-item-headdesc {
							font-size: 12px;
							text-align: left;
						}
					}
					.ctx-item-b {
						flex: 1;
						text-align:left;
						.hxs-warp {
							width: 100px !important;
							margin-right: 10px;
						}
						
					}
				}
			}
			.btn-foot {
				margin-top: 10px;
				.btn {
					width: 100px;
					margin: 0 auto;
					background-color: #c88854;
					color: #fff;
					padding: 10px 20px;
					border-radius: 30px;
					cursor: pointer;
				}
			}
		}
	}
</style>
