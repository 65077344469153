<template>
	<div class="container">
		<div class="search-box">
			<div class="search-bd">
				<div class="search_t">
					<el-radio-group v-model="recommendType">
						<el-radio :label="item.key" v-for="(item,index) in recommendLebels">{{item.value}}</el-radio>
					</el-radio-group>
				</div>
				
				<div class="search_c">
					<div class="search_item">
						
					</div>
				</div>
			</div>
			<div class="search_btn_warp" @click="dealSearch">
				<div class="search_btn">
					<div class="icon_search"></div>
					<div>搜索</div>
				</div>
				<div class="rest_btn" @click="goPublish">
					我要发布
				</div>
			</div>
		</div>
		<div v-loading="commendloading" style="margin-top: 40px;">
			<div v-if="commendReqs.length > 0" class="hundred search_body" >
				<div class="comms-warp">
					<div class="comms-item" v-for="(item,index) in commendReqs" :key="item.sdr_reqId">
						<storeCommendReq :baseUrlpath="baseUrlpath" :item="item"></storeCommendReq>
					</div>
				</div>
				<div class="pagination_warp">
					<el-pagination
						@current-change="handleCurrentChange"
						:current-page.sync="currentPage1"
						:page-size="pageSize"
						layout="total, prev, pager, next"
						:total="total">
					</el-pagination>
				</div>
			</div>
			<div v-else>
				<el-empty description="暂无内容"></el-empty>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		queryUrl,
		doPost
	} from '@/api/index';
	import storeCommendReq from '@/components/storeCommendReq.vue'
	export default {
		components: {
			storeCommendReq
		},
		data() {
			return {
				commendloading:false,
				commendReqs:[],
				recommendLebels:[],
				recommendType:'2',
				currentPage1:1,
				pageSize:12,
				baseUrlpath:'',
				total:0
			}
		},
		created() {
			this.baseUrlpath = this.baseUrl;
			if(this.$route.query.reqType){
				const queryReqType = this.$route.query.reqType;
				if(queryReqType == '11'){//房
					this.recommendType = '0'
				}else if(queryReqType == '9'){//车
					this.recommendType = '6'
				}else if(queryReqType == '10'){//旅游
					this.recommendType = '3'
				}else if(queryReqType == '12'){//美食
					this.recommendType = '5'
				}else if(queryReqType == '1'){//带货
					this.recommendType = '4'
				}else if(queryReqType == '3'){//招聘
					this.recommendType = '7'
				}
			}
			this.recommendLebels = JSON.parse(JSON.stringify(this.$util.storeTypeList));
			this.getStoreRecommens()
		},
		methods:{
			handleCurrentChange(e){
				// console.log(e)
				this.currentPage1 = e;
				this.commendReqs = [];
				this.total = 0;
				this.query()
			},
			goPublish(){
				this.$router.push({
				  name: '/storeRecommend/publish'
				});
			},
			dealSearch(){
				this.currentPage1 = 1;
				this.pageSize = 12;
				this.getStoreRecommens();
			},
			getStoreRecommens(type) {
				this.commendloading = true
				var vm = this;
				var params = {};
				params.currentPage = this.currentPage1;
				params.pageSize = this.pageSize;
				params.ssrStt = '0';
				params.srType = this.recommendType;
				queryUrl("/apis/getStoreRecommens", params).then(res => {
					vm.commendReqs = res.reqList;
					vm.total = res.totalcount;
					vm.commendloading = false
				});
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.search_body {
		min-height: 760px;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		.pagination_warp {
			padding: 0 20px 20px 0;
			margin-top: 60px;
		}
	}
	.container {
		position: relative;
		width: 90%;
		margin: 0 auto;
		align-items: flex-start;
	}
	.comms-warp  {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		.comms-item {
			width: calc((100% - 36px)/4);
			box-sizing: border-box;
			/* height: 170px; */
			overflow: hidden;
			box-shadow: 0px 4px 16px 1px rgb(0 0 0 / 6%);
		}
		.comms-item:not(:nth-child(4n)) {
			margin-right: 12px;
		}
	}
	.search-box {
		padding: 24px;
		box-shadow: 0 4px 16px rgba(69,88,115,.2);
		margin-bottom: 16px;
		background: #fff;
		border-radius: 12px;
		position: relative;
		.search-bd {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			min-height: 76px;
			.search_t {
				margin-bottom: 20px;
			}
			.search_c {
				margin-bottom: 20px;
				display: flex;
				.search_item {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					.sear_it_head {
						font-size: 14px;
					}
				}
				.search_item:not(:last-child) {
					margin-right: 20px;
				}
			}
	
		}
		.search_btn_warp {
			display: flex;
			position: absolute;
			left: 50%;
			bottom: -46px;
			position: absolute;
			transform: translate(-50%,-50%);
			.search_btn {
				background-image: linear-gradient(-90deg,#c88854,#e09a62);
				border-radius: 28px;
				box-shadow: 0 4px 16px 0 rgba(255,102,0,.32);
				width: 132px;
				height: 46px;
				border: none;
				display: block;
				outline: none;
				font-size: 18px;
				color: #fff;
				z-index: 2;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				margin-right: 8px;
				.icon_search {
					background: url(../../assets/icon/search_empty.png) no-repeat center;
					background-size: 100%;
					width: 26px;
					height: 26px;
					margin-right: 6px;
				}
			}
			.rest_btn {
				width: 132px;
				height: 46px;
				color: #C88854;
				border: 1px solid #C88854;
				line-height: 46px;
				cursor: pointer;
				border-radius: 28px;
				font-size: 18px;
				box-sizing: border-box;
				margin-left: 8px;
				background-color: #FFFFFF;
			}
			.rest_btn:hover {
				border: none;
				background-color: #C88854;
				color: #FFF7F0;
				outline: none;
			}
		}
	}
</style>
