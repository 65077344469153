<template>
	<div class="container">
		<div class="flex-between headwarp">
			<div class="head-item" @click="goChooseClassfiy()">1.选择类别</div>
			<div class="head-item active">2.填写信息</div>
			<div class="head-item">3.完成发布</div>
		</div>
		<div class="cont-body">
			<div class="content hundvh">
				<div class="ctx-item flex-center">
					<el-radio-group v-model="sfCarModel" class="flex-center">
						<el-radio v-for="(item,index) in modelArray" :label="item.key">{{item.value}}</el-radio>
					</el-radio-group>
				</div>
				<template v-if="sfCarModel.startsWith('nkcor')">
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">名称<label class="bishu">*</label></div>
						</div>
						<div class="ctx-item-b" >
							<el-input placeholder="请输入名称" v-model="storeCorName" maxlength="30" />
						</div>
					</div>
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">地址<label class="bishu">*</label></div>
						</div>
						<div class="ctx-item-b" >
							<el-input placeholder="请输入地址" v-model="storeCorAddr" maxlength="80" />
						</div>
					</div>
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">简介<label class="bishu">*</label></div>
						</div>
						<div class="ctx-item-b">
							<el-input type="textarea" v-model="storeCorDesc" :autosize="{ minRows: 4}" maxlength="200" placeholder="请输入简介！" show-word-limit></el-input>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">面向地区<label class="bishu">*</label></div>
						</div>
						<div class="ctx-item-b">
							<el-cascader
							    v-model="areaId"
								:props="cascaderprops"
							    :options="citysList"
							    @change="handleCityChange">
							</el-cascader>
						</div>
					</div>
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">出发地<label class="bishu">*</label></div>
						</div>
						<div class="ctx-item-b" >
							<el-input placeholder="请输入出发地点" v-model="fromWhere" maxlength="30"/>
						</div>
					</div>
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">目的地<label class="bishu">*</label></div>
						</div>
						<div class="ctx-item-b" >
							<el-input placeholder="请输入目的地点" v-model="toWhere" maxlength="30"/>
						</div>
					</div>
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">出发时间<label class="bishu">*</label></div>
						</div>
						<div class="ctx-item-b" >
							<el-date-picker
								v-model="datetimesingle"
								type="date"
								placeholder="选择日期时间"
								format="yyyy-MM-dd"
								value-format="yyyy-MM-dd"
								>
							</el-date-picker>
						</div>
					</div>
					<!-- <div class="ctx-item inlinediv">
						<div class="ctx-item-t">
							<div class="ctx-item-head">费用<text class="red">*</text></div>
						</div>
						<div class="ts-ctx" >
							<radio-group @change="radioChange">
								<radio style="transform:scale(0.7)" value="0" :checked="fyIndex == '0'"></radio><text>可协商</text>
								<radio style="transform:scale(0.7)" value="1" :checked="fyIndex == '1'"></radio><text>一口价</text>
							</radio-group>
						</div>
					</div> -->
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">价格(元)<label class="bishu">*</label></div>
						</div>
						<div class="ctx-item-b" >
							<el-input v-model="startSalary" type="digit" maxlength="8" placeholder="请输入价格" />
						</div>
					</div>
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">其他说明</div>
							<div class="ctx-item-headdesc">（选填）</div>
						</div>
						<div class="ctx-item-b">
							<el-input type="textarea" v-model="reqNail" :autosize="{ minRows: 4}" maxlength="200" placeholder="请输入内容" show-word-limit></el-input>
						</div>
					</div>
				</template>
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">是否置顶（30天）<label class="bishu">*</label></div>
					</div>
					<div class="ctx-item-b" style="text-align: right;">
						<el-switch
							v-model="tickTopOrNot"
							@change="tickTopChange"
							active-color="#13ce66"
							inactive-color="#ff4949">
						</el-switch>
					</div>
				</div>
				<div class="ctx-item inlinediv">
					<pickContact ref="pickContact" :queryUid="userInfo.fayanboId" :baseUrlpath="baseUrlpath" @changeConcactShow="changeConcactShow" :isShowConcact="isShowConcact" :isDetail="isDetail"></pickContact>
				</div>
				<div class="ctx-item" v-if="sfCarModel == '0' || sfCarModel.startsWith('nkcor')">
					<div class="ctx-item-t">
						<div class="ctx-item-head">上传照片/视频<label class="bishu">*</label></div>
						<div class="ctx-item-headdesc"><label>({{finalFileList.length}}/{{limitFilesNum}})</label></div>
					</div>
					<div class="ctx-item-b">
						<uploadFile :baseUrlpath="baseUrlpath" :fileList="fileList" @addOrMulitiFileList="addOrMulitiFileList"></uploadFile>
					</div>
				</div>
				<div class="ctx-item">
					<div class="ctx-item-b">
						<div class="pub_jd_cost">
							<div class="jd_total">
								<label class="unit">共需花费</label>
								<label class="num">{{pubTotalCost}}</label>
								<label class="unit">金豆</label>
							</div>
							<div class="cost_desc">
								<label>发布</label>
								<label class="num">{{pubNeedCost}}</label>
								<label>金豆</label>
								<template v-if="tickTopOrNot">
									<label>+</label>
									<label>置顶</label>
									<label class="num">{{tickTopNeedCost}}</label>
									<label>金豆</label>
								</template>
							</div>
						</div>
					</div>
				</div>
				<div class="req_tips">
					<div>1.支付前请确认与发布人确认好细节</div>
					<div>2.请尽量选择已担保客户已实名认证的客户</div>
					<div>3.本平台仅提供信息交流服务，不承担任何法律责任</div>
					<div>4.平台支付后请勿立即确认完成，订单服务完成后点击确认完成即可，确认完成后平台不可退款</div>
					<div>5.线上支付交易，平台收取6%服务费</div>
				</div>
				<div class="btn-foot">
					<div class="btn" @click="publish()">确认发布</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapGetters,mapActions} from 'vuex'
	import uploadFile from '@/components/upload/uploadFile.vue'
	import pickContact from '@/components/pickContact.vue'
	import {queryUrl,doPost,commitFileUrl,queryUrlWithUInfo,postWithUInfo} from '@/api/index';
	import { Toast } from 'mint-ui';
	export default {
		components:{
		  uploadFile,pickContact
		},
		data(){
			return {
				cascaderprops:{
					value:'id',
					label:'name',
					children:'child'
				},
				reqNail:'',
				modelArray:[],
				storeCorName:'',
				storeCorlatitude:'',
				storeCorlongitude:'',
				storeCorAddr:'',
				storeCorDesc:'',
				sfCarModel:'0',//0:车找人 1：人找车
				fyIndex:'1',
				areaId:'',
				startSalary:'',
				fromWhere:'',
				toWhere:'',
				indicatorStyle: `height: 50px;`,
				maskStyle:``,
				fileList:[],
				finalFileList:[],
				limitFilesFlg:true,
				limitFilesNum:9,
				baseUrlpath:'',
				datetimesingle:'',
				dqList:[],
				citysList:[],
				isShowConcact:true,
				tickTopOrNot:false,
				tickTopNeedCost:0,
				pubNeedCost:0,
				pubTotalCost:0,
				isDetail:false,
				sdr_userId:'',
				reqNo:''
			}
		},
		created() {
			// console.log(this.$route.query)
			this.reqType = this.$route.query.reqType;
			this.citysList = this.$util.citysList;
			this.modelArray = this.$util.carModelList;
			this.queryTickJdCost()
			this.queryPubJdCost()
		},
		computed: {
			...mapGetters(['userInfo'])
		},
		watch:{
			tickTopNeedCost:{
				handler(val, oldval) {
					if(this.tickTopOrNot){
						this.pubTotalCost = parseInt(val) + parseInt(this.pubNeedCost) 
					}else{
						this.pubTotalCost = parseInt(this.pubNeedCost)
					}
				}
			},
			pubNeedCost:{
				handler(val, oldval) {
					if(this.tickTopOrNot){
						this.pubTotalCost = parseInt(val) + parseInt(this.tickTopNeedCost) 
					}else{
						this.pubTotalCost = parseInt(val)
					}
				}
			},
			tickTopOrNot:{
				handler(val, oldval) {
					console.log(val)
					if(val){
						this.pubTotalCost = parseInt(this.pubNeedCost) + parseInt(this.tickTopNeedCost) 
					}else{
						this.pubTotalCost = parseInt(this.pubNeedCost)
					}
				}
			}
		},
		methods:{
			tickTopChange(e){
				var needCost = this.tickTopNeedCost;
				var _this = this;
				console.log(e)
				if(e){
					this.$confirm('置顶需花费'+needCost+'金豆'+', 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						
					}).catch(() => {
						_this.tickTopOrNot = false       
					});
				}
			},
			async queryTickJdCost(){
				const initres = await queryUrlWithUInfo('/apis/queryJdNeedCost',{queryType:'1'});
				if(initres.ec == '0'){
					this.tickTopNeedCost = parseInt( initres.jdCost)
				}
			},
			async queryPubJdCost(){
				const initres = await queryUrlWithUInfo('/apis/pubJdNeedCost',{queryType:'1'});
				if(initres.ec == '0'){
					this.pubNeedCost = parseInt(initres.jdCost)
				}
			},
			changeConcactShow(val){
				this.isShowConcact = val
			},
			goBack() {
				this.$util.goBack();
			},
			async publish(){
				var vm = this;
				if(this.sfCarModel.startsWith('nk')){
					if(this.sfCarModel.startsWith('nkcor')) {
						if(this.storeCorName.length == 0){
							this.$util.warnMsg('请输入名称');
							return false;
						}
						if(this.storeCorAddr.length == 0){
							this.$util.warnMsg('请输入地址');
							return false;
						}
						if(this.storeCorDesc.length == 0){
							this.$util.warnMsg('请输入简介');
							return false;
						}
					}
					if(this.finalFileList.length > 0){
						this.$util.warnMsg('请上传图片');
						return false;
					}
				}else{
					if(this.fromWhere.length == 0){
						this.$util.warnMsg('请选择出发地');
						return false;
					}
					if(this.toWhere.length == 0){
						this.$util.warnMsg('请选择目的地');
						return false;
					}
					if(this.datetimesingle.length == 0){
						this.$util.warnMsg('请选择出发时间');
						return false;
					}
					if(this.areaId.length == 0){
						this.$util.warnMsg('请选择面向地区');
						return false;
					}
					if(this.fyIndex == '1'){
						if(this.startSalary.length == 0){
							this.$util.warnMsg('请输入价格');
							return false;
						}
					}
					if(this.sfCarModel == '0'){
						if(this.finalFileList.length > 0){
							this.$util.warnMsg('请上次图片');
							return false;
						}
					}
				}
				vm.$util.showMyLoading('加载中');
				var params = {};
				params.reqType = "2";
				params.fyIndex = this.fyIndex;
				params.storeCorName = this.storeCorName;
				params.storeCorAddr = this.storeCorAddr;
				params.storeCorDesc = this.storeCorDesc;
				params.storeCorlatitude = this.storeCorlatitude;
				params.storeCorlongitude = this.storeCorlongitude;
				params.reqNail = this.$util.textFormat(this.reqNail);
				params.datetimesingle = this.datetimesingle;
				params.dqModel = JSON.stringify(this.areaId);
				params.fileList = JSON.stringify(this.finalFileList);
				params.startSalary = this.startSalary;
				params.fromWhere = this.fromWhere;
				params.toWhere = this.toWhere;
				params.sfCarModel = this.sfCarModel;
				params.tickTopOrNot = this.tickTopOrNot ? '1' : '0';
				params.isShowConcact = this.isShowConcact ? '1' : '0';
				const initres = await postWithUInfo('/apis/publishReqPost',params);
				if(initres.ec == '0'){
					vm.$util.hideMyLoading();
					this.$util.msg("发布成功",{},()=>{
						vm.$util.goBack();
					});
				}else{
					vm.$util.hideMyLoading();
					this.$util.msg("发布失败，请联系客服",{},()=>{
						
					});
				}
			},
			goChooseClassfiy(){
				this.$router.push({
				  name: 'publishIndex',
				  query: {}
				});
			},
			addOrMulitiFileList(params){
				// console.log(params)
				if(params.type == '1'){
					this.finalFileList.push({fileId:params.file.fileId,path:params.file.path,url:params.file.path,thumbPath:params.file.thumbPath})
					// console.log(this.fileList)
				}else if(params.type == '2'){
					this.finalFileList = this.finalFileList.filter((item,index) => {
						return item.fileId != params.file.fileId
					});
					// console.log(this.fileList)
				}
			},
			handleCityChange(e){
				// console.log(e)
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.container {
		width: 90%;
		margin: 0 auto;
		box-shadow: 0px 4px 16px 1px rgb(0 0 0 / 6%);
		height: 100%;
		padding: 10px 20px;
		.headwarp {
			.head-item {
				width: 30%;
				text-align: left;
				line-height: 50px;
				border-bottom: 2px solid #F1F1F1;
			}
			.active {
				border-bottom: 2px solid #C88854;
			}
		}
		.cont-body {
			margin-top: 20px;
			//overflow: scroll;
			min-height: calc(100% - 100px);
			.content {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.ctx-item {
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 10px 0;
					width: 600px;
					.bishu {
						color: #FF0000;
						margin-left: 4px;
					}
					.ctx-item-t {
						margin-right: 10px;
						.ctx-item-head {
							line-height: 40px;
							width: 160px;
							text-align: left;
							font-size: 14px;
							font-weight: 700;
						}
						.ctx-item-headdesc {
							font-size: 12px;
							text-align: left;
						}
					}
					.ctx-item-b {
						flex: 1;
						text-align:left;
					}
				}
			}
			.btn-foot {
				margin-top: 10px;
				.btn {
					width: 100px;
					margin: 0 auto;
					background-color: #c88854;
					color: #fff;
					padding: 10px 20px;
					border-radius: 30px;
					cursor: pointer;
				}
			}
		}
	}
</style>
