import { render, staticRenderFns } from "./pubDiscounts.vue?vue&type=template&id=65ad2f43&scoped=true&"
import script from "./pubDiscounts.vue?vue&type=script&lang=js&"
export * from "./pubDiscounts.vue?vue&type=script&lang=js&"
import style0 from "./pubDiscounts.vue?vue&type=style&index=0&id=65ad2f43&prod&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65ad2f43",
  null
  
)

export default component.exports