<template>
	<div>
		<div class="container flex-start">
			<div class="search">
				<div class="flex-start">
					<div class="search_item">
						<div>审核状态：</div>
						<el-select v-model="reqOrderType" placeholder="请选择">
							<el-option v-for="item in orderTypeList" :key="item.key" :label="item.value"
								:value="item.key">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="btn_warp">
					<div class="btn" @click="queryBtn">查询</div>
					<div class="btn" @click="addAd">广告投放</div>
				</div>
			</div>
			<div v-loading="loading" class="req-warp">
				<div v-if="tableData.length > 0">
					<div class="req-warp">
						<el-table :data="tableData" border style="width: 100%">
							<el-table-column fixed align="center" label="广告图" width="200">
								<template slot-scope="scope">
									<div class="flex-center avatarwarp">
										<div class="avatar">
											<img :src="showAvatarUrl(scope.row.sdam_ad_filepath)"></img>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="reqTypeName" align="center" label="投放区域" width="140">
							</el-table-column>
							<el-table-column prop="sdam_days" align="center" label="投放时长(天)" width="260">
								<template slot-scope="scope">
									<div>{{scope.row.sdam_days}}天</div>
									<div class="flex-center" style="color: #FF0000;font-size: 12px;">
										<div>{{$util.formateSelfDate(scope.row.sdam_addTime)}}</div>
										<div>~</div>
										<div>{{$util.formateSelfDate(scope.row.sdam_addTime)}}</div> 
									</div>
								</template>
							</el-table-column>
							<el-table-column align="center" prop="sdam_stt" label="审核状态" width="240">
								<template slot-scope="scope">
									<div>{{$util.getDisPlay(scope.row.sdam_stt,$util.examineSttList)}}</div> 
									<div class="flex-center" style="color: #FF0000;font-size: 12px;" v-if="scope.row.sdam_stt == '2'">
										<div>拒绝原因:</div>
										<div>{{scope.row.sdam_rejectMsg}}</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column align="center" prop="sdam_remark" label="广告描述" width="240">
							</el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="160">
								<template slot-scope="scope">
									<el-button @click="handleComfireClick(scope.row)" type="text" size="small" >修改</el-button>
									<el-button type="text" size="small" @click="handleRejectClick(scope.row)" >删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="pagination_warp">
						<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage1"
							:page-size="pageSize" layout="total, prev, pager, next" :total="total">
						</el-pagination>
					</div>
				</div>
				<div v-else class="empty-msg">
					<el-empty description="暂无内容"></el-empty>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		queryUrl,
		doPost
	} from '@/api/index';
	import {
		Toast
	} from 'mint-ui';
	export default {
		data() {
			return {
				currentPage1: 1,
				tableData: [],
				total: 0,
				pageSize: 9,
				baseUrlpath: '',
				reqType: 'all',
				reqTypeList: [],
				reqOrderType: 'all',
				orderTypeList: [],
				loading: false
			}
		},
		created() {
			this.baseUrlpath = this.baseUrl;
			this.reqTypeList = JSON.parse(JSON.stringify(this.$util.reqList));
			this.reqTypeList.unshift({
				'key': 'all',
				'value': '不限'
			})
			this.orderTypeList = JSON.parse(JSON.stringify(this.$util.examineSttList));
			this.orderTypeList.unshift({
				'key': 'all',
				'value': '不限'
			})
			this.query();
		},
		methods: {
			handleCurrentChange(e) {
				// console.log(e)
				this.currentPage1 = e;
				this.tableData = [];
				this.total = 0;
				this.query()
			},
			handleComfireClick(info) {
				this.$router.push({
					name: '/my/modifyAd',
					query: {
						adId: info.sdam_adid
					}
				});
			},
			handleRejectClick(info) {
				var vm = this;
				this.$confirm('是否确定删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					vm.dealReject(info);
				})
			},
			async dealReject(info) {
				this.loading = true
				var vm = this;
				var params = {};
				params.adId = info.sdam_adid
				const initres = await queryUrl("/apis/soldOutAd", params);
				if (initres.ec == '0') {
					this.$util.msg("删除成功");
					this.query();
				}
				this.loading = false
			},
			queryBtn() {
				this.currentPage1 = 1;
				this.query();
			},
			addAd(){
				this.$router.push({
					name: '/my/launchAd'
				});
			},
			query() {
				this.loading = true
				var vm = this;
				var params = {};
				params.currentPage = vm.currentPage1;
				params.pageSize = vm.pageSize;
				params.examineStt = vm.reqOrderType;
				queryUrl("/apis/queryMyAdvers", params).then(res => {
					this.loading = false
					vm.tableData = res.reqList;
					vm.total = res.totalNum;
				});
			},
			goToDetail(itemInfo) {
				this.$router.push({
					name: 'reqDetail',
					query: {
						reqNo: itemInfo.sdr_reqId
					}
				});
			},
			showAvatarUrl(avatarUrl) {
				if (avatarUrl) {
					if (avatarUrl.indexOf('http') >= 0) {
						return avatarUrl;
					} else {
						return this.baseUrlpath + avatarUrl;
					}
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.req-warp {
		width: 100%;

		.avatarwarp {
			.avatar {
				width: 30px;
				height: 30px;
				overflow: hidden;
				border-radius: 50px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.nickName {
				font-size: 12px;
				padding-left: 26px;
				width: 100px;
				text-align: left;
			}
		}
	}

	.container {
		width: 90%;
		margin: 0 auto;
		align-items: flex-start;
		flex-direction: column;

		.pagination_warp {
			padding: 0 20px 20px 0;
			margin-top: 60px;
			width: 100%;
		}

		.empty-msg {
			flex: 1;
			height: calc(100vh - 70px);
			display: flex;
			justify-content: center;
			width: 100%;
		}

		.search {
			margin-top: 20px;
			margin-bottom: 30px;
			display: flex;
			align-items: center;

			.search_item {
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}

			.search_item:not(:first-child) {
				margin-left: 26px;
			}

			.btn_warp {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-left: 20px;

				.btn {
					background-color: #C88854;
					color: #FFFFFF;
					width: 80px;
					margin: 0 10px;
					padding: 6px 0;
					border-radius: 20px;
					font-size: 14px;
					cursor: pointer;
				}

				.rest_btn {
					border: 1px solid #C88854;
					color: #C88854;
					width: 80px;
					margin: 0 10px;
					padding: 6px 0;
					border-radius: 20px;
					font-size: 14px;
					cursor: pointer;
				}
			}
		}
	}

	.req-item {
		border-radius: 8px;
		box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.06);
		overflow: auto;
		margin-right: 12px;
		margin-bottom: 12px;
		padding: 20px 20px;
	}
</style>
