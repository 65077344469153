<template>
	<div class="container">
		<el-page-header @back="goBack" content="订单支付">
		</el-page-header>
		<div class="item-warp conarea">
			<div class="item-head flex-between">
				<div class="flex-start">
					<div class="avatar">
						<img :src="showAvatarUrl(item.sellerAvatarUrl)"></img>
					</div>
					<div class="nickname">{{item.sellerName}}</div>
				</div>
			</div>
			<div class="item-body flex-start">
				<div class="attach">
					<img :src="showAvatarUrl(item.attachPath)"></img>
				</div>
				<div class="bodynail">
					<div class="body_center">
						<div class="flex-between hundred">
							<div class="flex-start fontsmc" v-if="item.sdr_reqType == '4'">
								<div class="margr4">{{$util.getDisPlay(item.sdr_reqType,$util.reqList)}}</div>
								<div class="margr4">|</div>
								<div>{{item.sdr_proName}}</div>
							</div>
							<div class="flex-start fontsmc" v-if="item.sdr_reqType == '1'">
								<div class="margr4">{{$util.getDisPlay(item.sdr_reqType,$util.reqList)}}</div>
								<div class="margr4">|</div>
								<div>{{$util.getDisPlay(item.sdr_carry_model,$util.carryModelList)}}</div>
							</div>
							<div class="flex-start fontsmc" v-if="item.sdr_reqType == '2'">
								<div class="margr4">{{$util.getDisPlay(item.sdr_reqType,$util.reqList)}}</div>
								<div class="margr4">|</div>
								<div>{{$util.getDisPlay(item.sdr_sfcar_model,$util.carModelList)}}</div>
							</div>
							<div class="flex-start fontsmc" v-if="item.sdr_reqType == '10'">
								<div class="margr4">{{$util.getDisPlay(item.sdr_reqType,$util.reqList)}}</div>
								<div class="margr4">|</div>
								<div>{{$util.getDisPlay(item.sdr_travel_tag,$util.travelLebels)}}</div>
							</div>
						</div>
						<div class="conts">{{item.sdr_contents}}</div>
					</div>
					<div class="bodyfoot">
						<div class="flex-end lb_warp">
							<div class="sttdesc" v-if="item.srwp_payStt == '1'">已付款</div>
							<div class="sttdesc" v-if="item.srwp_payStt == '3'">确认完成</div>
							<div class="sttdesc" v-if="item.srwp_payStt == '0'">待付款</div>
							<div class="sttdesc" v-if="item.srwp_payStt == '4'">已取消</div>
							<div class="sttdesc" v-if="item.srwp_payStt == '5'">支付超时</div>
							<div class="flex-start">
							<div class="margr10">金额</div>
								<div class="amt_warp">
									<div class="amticon">¥</div>
									<div class="amtnum">{{item.stof_amt}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="aOrderInfo conarea">
			<div>
				<div class="aoi_item">
					<div class="label">订单状态：</div>
					<div class="info-rcol">{{aOrderNo}}</div>
				</div>
				<div class="aoi_item">
					<div class="label">订单编号：</div>
					<div class="info-rcol">{{aOrderNo}}</div>
				</div>
				<div class="aoi_item">
					<div class="label">订单金额：</div>
					<div class="info-rcol">
						<label>{{aOrderAmt}}</label>
						<label>元</label>
					</div>
				</div>
				<div class="aoi_item">
					<div class="label">下单时间：</div>
					<div class="info-rcol">{{aOrderTime}}</div>
				</div>
			</div>
			<div>
				
			</div>
		</div>
		<div class="btn_area conarea">
			<div class="flex-start">
				<el-popover placement="right" trigger="manual" v-model="wxPayQRcode">
					<div class="wxqrcode_warp" >		
						<div class="icon_colse" @click="colseWxQrcode()"></div>
						<div class="wxqrcode_warp_left" v-loading="wxQrcoswloading">
							<div id="wxqrcode" :style="{width:'200px',height:'200px'}"></div>
							<div class="wxqrcode_bottom">
								<div class="sys_icon"></div>
								<div class="sys-desc">
									<div>请使用微信扫一扫</div>
									<div>扫描二维码支付</div>
								</div>
							</div>
						</div>
						<div class="wxqrcode_warp_right"></div>
					</div>	
					<div class="rest_btn" slot="reference" @click="showWXQRcode()">
						<div class="icon_weixin"></div>
						<div>微信支付</div>
					</div>
				</el-popover>
				<el-popover placement="right" width="400" trigger="manual" v-model="zfbPayQRcode">
					<el-image
					      style="width: 400px; height: 400px"
					      :src="qrcodeWx"
					      :fit="fit"></el-image>
					<div class="rest_btn" slot="reference" @click="showZFBQRcode()">
						<div class="icon_zfb"></div>
						<div>支付宝支付</div>
					</div>
				</el-popover>
			</div>
		</div>
		
	</div>	
</template>

<script>
	import {
		queryUrl,
		doPost,
		queryUrlWithUInfo,
		postWithUInfo
	} from '@/api/index';
	import QRCode from 'qrcodejs2'
	export default {
		data() {
			return {
				defalutImage:require('../../../assets/icon/noimg.jpg'),
				item:{},
				aOrderAmt:'',
				aOrderTime:'',
				aOrderNo:'',
				wxPayQRcode:false,
				wxQrcoswloading:true
			}
		},
		created(){
			this.item = JSON.parse( this.$route.query.orderInfo);
			this.getWaitPayInfo(this.item.srwp_payNo);
		},
		methods:{
			showWXQRcode(){
				this.$util.checkLogin();
				this.$confirm('是否确定支付？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.createWxNative();
				})
			},
			async createWxNative(){
				var params = {};
				params.aOrderNo = this.aOrderNo;
				const initres = await postWithUInfo("/apis/createNative",params);
				if(initres.ec == '0'){
					this.createWxqrcode(initres.code_url);
				}
			},
			createWxqrcode(code) {
				this.wxPayQRcode = true;
				document.getElementById("wxqrcode").innerHTML = "";
				this.wxqrcode = new QRCode('wxqrcode', {
					width: 200, //二维码宽度
					height: 200, // 二维码高度
					text: code, // 二维码内容，这里就是后端返回的字符串
					render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
				});
				this.wxQrcoswloading = false
			},
			colseWxQrcode(){
				this.wxPayQRcode = false
			},
			async getWaitPayInfo(aOrderNo) {
				var vm = this;
				const initres = await queryUrlWithUInfo("/apis/getPayWaitReqInfo",{aOrderNo: aOrderNo});
				if (initres.ec == '0') {
					this.aOrderAmt = initres.aOrderAmt;
					this.aOrderTime = initres.aOrderTime;
					this.reqExpire = initres.reqExpire;
					this.aOrderNo = aOrderNo;
				}
			},
			showAvatarUrl(avatarUrl) {
				if (avatarUrl) {
					if (avatarUrl.indexOf('http') >= 0) {
						return avatarUrl;
					} else {
						return this.baseUrlpath + avatarUrl;
					}
				}
			},
			goBack() {
				this.$util.goBack();
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.container {
		width: 90%;
		margin: 0 auto;
		.conarea {
			width: 100%;
			background: #fff;
			box-shadow: 0 6px 32px rgb(0 0 0 / 13%);
			padding: 30px 30px 30px 30px;
			box-sizing: border-box;
			margin-bottom: 25px;
		}
	}
	.wxqrcode_warp {
		display: flex;
		justify-content: space-around;
		align-items: center;
		//padding: 20px 0px 20px 20px;
		position: relative;
		.icon_colse {
			width: 26px;
			height: 26px;
			background: url(../../../assets/icon/close.png) center no-repeat;
			background-size: 100%;
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;
		}
		.wxqrcode_warp_left {
			display: flex;
			flex-direction: column;
			.wxqrcode_bottom {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 20px;
				.sys-desc {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex-direction: column;
					font-size: 14px;
					color: #1d1b1b;
				}
				.sys_icon {
					background: url(../../../assets/icon/saoyisao.png) center no-repeat;
					background-size: 100%;
					width: 30px;
					height: 30px;
					margin-right: 20px;
				}
			}
		}
		.wxqrcode_warp_right {
			width: 200px;
			height: 200px;
			background: url(../../../assets/icon/pc_icon_phone-bg.png) center no-repeat;
			background-size: 80%;
		}
	}
	.btn_area {
		.rest_btn {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 20px;
			cursor: pointer;
			.icon_weixin {
				width: 30px;
				height: 30px;
				background: url(../../../assets/icon/PAY-WEIXIN.png) no-repeat center;
				background-size: 100%;
				margin-right: 4px;
			}
		
			.icon_zfb {
				width: 30px;
				height: 30px;
				background: url(../../../assets/icon/zfb.png) no-repeat center;
				background-size: 100%;
				margin-right: 4px;
			}
		}
		
		.rest_btn:hover {
			color: #c88854;
		}
	}
	.aOrderInfo {
		.aoi_item {
			line-height: 40px;
			display: flex;
			.label {
				width: 184px;
				text-align: left;
			}
			.info-rcol {
				width: 278px;
				text-align: left;
			}
		}
	}
	.item-warp {
		margin-top: 40px;
		display: flex;
		.item-foot {
			margin-top: 20px;
			.btn {
				border: 1px solid #7b7777;
				padding: 0 12px;
				border-radius: 6px;
				font-size: 13px;
				line-height: 24px;
				cursor: pointer;
				margin: 0 6px;
			}
		}
		.item-head {
			margin-bottom: 10px;
			.avatar {
				width: 30px;
				height: 30px;
				overflow: hidden;
				border-radius: 50px;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.nickname {
				font-size: 12px;
				padding-left: 26px;
				width: 100px;
				text-align: left;
			}
		}
		.item-body {
			height: 100px;
			flex: 1;
			margin-left: 20px;
			.attach {
				width: 100px;
				height: 100px;
				overflow: hidden;
				border-radius: 10px;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.bodynail {
				height: 100%;
				flex: 1;	
				display: flex;
				justify-content: space-between;
				.body_center {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: space-around;
					margin-left: 20px;
				}
				.fontsmc {
					color: #C88854;
					font-size: 14px;
					text-align: left;
				}
				.conts {
					text-align: left;
					width: 100%;
					font-size: 14px;
				}
				.bodyfoot {
					font-size: 14px;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					.lb_warp {
						line-height: 28px;
						
					}
					.sttdesc {
						color: #C88854;
						margin-right: 20px;
						font-weight: bold;
					}
				}
			}
		}
	}
	.amt_warp {
		display: flex;
		justify-content: center;
		align-items: baseline;
		color: #F44336;
		.amtnum {
			font-size: 16px;
			font-weight: bold;
			margin-left: 2px;
		}
	}
</style>
