<template>
	<div id="weixin" class="container">
		<el-page-header @back="goBack" content="修改">
		</el-page-header>
		<div class="login-warp">
			<div class="input_item">
				<div class="ctx-item-head">广告投放区域</div>
				<div class="input_warp">
					{{reqTypeName}}
				</div>
			</div>
			<div class="input_item">
				<div class="ctx-item-head">广告投放时长</div>
				<div class="input_warp">
					{{days}}天
				</div>
			</div>
			<!-- <div class="input_item">
				<div class="ctx-item-head">广告费用(元)</div>
				<div class="input_warp">
					<el-input placeholder="广告费用" :disabled="true" v-model="amt"></el-input>
				</div>
			</div> -->
			<div class="input_item">
				<div class="ctx-item-head">广告描述</div>
				<div class="input_warp">
					<el-input placeholder="请输入想要投放的广告描述" v-model="contents" type="textarea" maxlength="200" :rows="4">
					</el-input>
				</div>
			</div>
			<div class="input_item" >
				<div class="ctx-item-head">广告图</div>
				<div class="input_warp">
					<el-upload action="123" class="upload-demo" :http-request="uploadFilePassPort" :limit="1" :file-list="fileList">
						<el-button size="small" type="primary">点击上传广告图</el-button>
						<div slot="tip" class="el-upload__tip">建议尺寸388*120像素</div>
					</el-upload>
				</div>
			</div>
			<!-- <div class="input_item" style="margin-top: 100px;">
				<zhifu @requestWxQrcode="requestWxQrcode" @requestAlipayQrcode="requestAlipayQrcode" @payFinishCallback="payFinishCallback" ref="zhifu"></zhifu>
			</div> -->
			<div>
				<div class="btn" @click="dealEmailLogin">修改</div>
			</div>
		</div>
	</div>
</template>

<script>
	import uploadFile from '@/components/upload/uploadFile.vue'
	import zhifu from '@/components/zhifu/index.vue'
	import {
		queryUrl,
		doPost,
		commitFileUrl,queryUrlWithUInfo,postWithUInfo
	} from '@/api/index';
	export default {
		components: {
			uploadFile,zhifu
		},
		data() {
			return {
				adId:'',
				tel: '',
				contents: '',
				adListInfo: [],
				adReqTypes: [],
				pickTimesList: [],
				reqType: '',
				timeType: '',
				amt: '',
				adfilePath: '',
				fileList:[],
				wxPayQRcode:false,
				zfbPayQRcode:false,
				wxQrcoswloading:true,
				alipayQrcoswloading:true,
				reqTypeName:'',
				days:''
			}
		},
		created() {
			this.adId = this.$route.query.adId
			this.getAdItemsList()
			this.getAdInfo(this.adId)
		},
		methods: {
			async getAdInfo (adId){
				const initres = await queryUrlWithUInfo("/apis/queryAdInfo",{adId:adId});
				if(initres.ec == '0'){
					this.fileList = [];
					var fileName = initres.sdam_ad_filepath.substring(initres.sdam_ad_filepath.lastIndexOf('/') + 1)
					this.fileList.push({url:initres.sdam_ad_filepath,name:fileName})
					this.reqTypeName = initres.reqTypeName
					this.adfilePath = initres.sdam_ad_filepath
					this.days = initres.sdam_days
					this.contents = initres.sdam_remark
				}
			},
			goBack() {
				this.$util.goBack()
			},
			onSuccessFile(file, fileList){
				console.log(file, fileList);
			},
			payFinishCallback(){
				this.$router.push({
					name: '/my/advertisements'
				});
			},
			checkValid(){
				if(this.reqType.length == 0){
					this.$message.error('请选择投放区域');
					return false;
				}
				if(this.timeType.length == 0){
					this.$message.error('请选择投放时长');
					return false;
				}
				if(this.contents.length == 0){
					this.$message.error('请输入广告描述');
					return false;
				}
				if(this.adfilePath.length == 0){
					this.$message.error('请上传广告图');
					return false;
				}
				return true
			},
			async requestWxQrcode(){
				if(this.checkValid()){
					this.$refs.zhifu.wxPayQRcode = true;
					if(this.adId.length > 0){
						this.pcAdWxCzOfH5QrCode(this.adId);
					}else{
						var params = {};
						params.reqType = this.reqType;
						params.pickTime = this.timeType;
						params.filePath = this.adfilePath;
						params.reqNail = this.contents
						params.launchType = '1';
						const initres = await queryUrlWithUInfo("/apis/launchAd",params);
						if(initres.ec == '0'){
							this.adId = initres.adid;
							this.pcAdWxCzOfH5QrCode(this.adId);
						}
					}
				}
			},
			async requestAlipayQrcode(){
				if(this.checkValid()){
					this.$refs.zhifu.zfbPayQRcode = true;
					if(this.adId.length > 0){
						this.pcAdAlipayCzOfH5QrCode(this.adId);
					}else{
						var params = {};
						params.reqType = this.reqType;
						params.pickTime = this.timeType;
						params.filePath = this.adfilePath;
						params.reqNail = this.contents;
						params.launchType = '1';
						const initres = await queryUrlWithUInfo("/apis/launchAd",params);
						if(initres.ec == '0'){
							this.adId = initres.adid;
							this.pcAdAlipayCzOfH5QrCode(this.adId);
						}
					}
				}
			},
			async pcAdWxCzOfH5QrCode(adId){
				var params = {};
				params.adid = adId;
				const initres = await postWithUInfo("/apis/pcAdWxCzOfH5QrCode",params);
				if(initres.ec == '0'){
					this.$refs.zhifu.createWxqrcode(initres.code_url,initres.outTradeNo)
				}
			},
			async pcAdAlipayCzOfH5QrCode(adId){
				var params = {};
				params.adid = adId;
				const initres = await postWithUInfo("/apis/alipay/pcAdAlipayCzOfH5QrCode",params);
				if(initres.ec == '0'){
					this.$refs.zhifu.createAlipayqrcode(initres.code_url,initres.outTradeNo)
				}
			},
			uploadFilePassPort(file) {
				// console.log(file)
				var vm = this;
				let formData = new FormData();
				formData.append('multipartfiles', file.file);
				commitFileUrl("/apis/addImg", formData).then(res => {
					if (res.ec == '0') {
						this.adfilePath = res.path
					}
				});
			},
			changeReqType(event) {
				console.log(event)
				this.pickTimesList = []
				for (var index in this.adListInfo) {
					if (this.adListInfo[index].sdac_reqType == event) {
						this.pickTimesList.push({
							'key': this.adListInfo[index].sdac_ad_validateTime,
							'value': this.adListInfo[index].sdac_ad_validateName
						})
					}
				}
				this.computAmt()
			},
			changeTimeType(event) {
				this.timeType = event
				this.computAmt()
			},
			computAmt() {
				if (this.timeType.length > 0) {
					var chooseReqType = this.reqType
					var chooseValidate = this.timeType
					for (var index in this.adListInfo) {
						if (this.adListInfo[index].sdac_reqType == chooseReqType && this.adListInfo[index]
							.sdac_ad_validateTime == chooseValidate) {
							this.amt = this.adListInfo[index].sdac_cost
						}
					}
				} else {
					this.amt = 0
				}
			},
			async getAdItemsList() {
				var ress = await queryUrl("/apis/queryAdPickInfo", {});
				if (ress.ec == '0') {
					this.adListInfo = ress.adListInfo
					this.adReqTypes = ress.adReqTypes
				}
			},
			async dealEmailLogin() {
				var _this = this;
				
				if(this.adfilePath.length == 0){
					this.$util.msg('请上传图片');
					return false;
				}
				if(this.contents.length == 0){
					this.$util.msg('请输入广告阐述');
					return false;
				}
				console.log(this.fileList)
				var params = {};
				params.filePath = this.adfilePath;
				params.adId = this.adId;
				params.reqNail = this.contents;
				const initres = await queryUrlWithUInfo("/apis/modifyAd",params);
				if(initres.ec == '0'){
					this.$util.msg("修改成功");
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.container {
		width: 90%;
		margin: 0 auto;
		box-shadow: 0px 4px 16px 1px rgb(0 0 0 / 6%);
		height: 100%;
		padding: 10px 20px;
	}
	.login-warp {
		.input_item {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;
			position: relative;
			.ctx-item-head {
				line-height: 40px;
				width: 160px;
				text-align: left;
				font-size: 14px;
				font-weight: 700;
			}
			.item_head {
				width: 70px;
				text-align: right;
			}

			.input_warp {
				position: relative;
				width: 320px;
				text-align: left;
			}

			.yzm {
				position: absolute;
				top: 0;
				right: 0;
				font-size: 14px;
				/* background-color: #c88854; */
				color: #fff;
				line-height: 39px;
				padding: 0 10px;
				box-sizing: border-box;
				color: #222226;
				cursor: pointer;
			}

			.awaitSenconds {
				position: absolute;
				top: 10px;
				right: -70px;
				color: red;
			}
		}

		.btn {
			width: 100px;
			margin: 0 auto;
			background-color: #c88854;
			color: #fff;
			line-height: 36px;
			border-radius: 20px;
			margin-top: 50px;
			cursor: pointer;
		}

		.el-select {
			width: 100%;
		}

	}
</style>
