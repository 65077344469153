<template>
	<div class="container">
		<el-page-header @back="goBack" content="订单详情">
		</el-page-header>
		<div class="item-warp">
			<div class="item-head flex-between">
				<div class="flex-start">
					<div class="avatar">
						<img :src="showAvatarUrl(item.sellerAvatarUrl)"></img>
					</div>
					<div class="nickname">{{item.sellerName}}</div>
				</div>
			</div>
			<div class="item-body flex-start">
				<div class="attach">
					<img :src="showAvatarUrl(item.attachPath)"></img>
				</div>
				<div class="bodynail">
					<div class="body_center">
						<div class="flex-between hundred">
							<div class="flex-start fontsmc" v-if="item.sdr_reqType == '4'">
								<div class="margr4">{{$util.getDisPlay(item.sdr_reqType,$util.reqList)}}</div>
								<div class="margr4">|</div>
								<div>{{item.sdr_proName}}</div>
							</div>
							<div class="flex-start fontsmc" v-if="item.sdr_reqType == '1'">
								<div class="margr4">{{$util.getDisPlay(item.sdr_reqType,$util.reqList)}}</div>
								<div class="margr4">|</div>
								<div>{{$util.getDisPlay(item.sdr_carry_model,$util.carryModelList)}}</div>
							</div>
							<div class="flex-start fontsmc" v-if="item.sdr_reqType == '2'">
								<div class="margr4">{{$util.getDisPlay(item.sdr_reqType,$util.reqList)}}</div>
								<div class="margr4">|</div>
								<div>{{$util.getDisPlay(item.sdr_sfcar_model,$util.carModelList)}}</div>
							</div>
							<div class="flex-start fontsmc" v-if="item.sdr_reqType == '10'">
								<div class="margr4">{{$util.getDisPlay(item.sdr_reqType,$util.reqList)}}</div>
								<div class="margr4">|</div>
								<div>{{$util.getDisPlay(item.sdr_travel_tag,$util.travelLebels)}}</div>
							</div>
						</div>
						<div class="conts">{{$util.mySubStr($util.filterHtml(item.sdr_contents),200)}}</div>
					</div>
					<div class="bodyfoot">
						<div class="flex-end lb_warp">
							<div class="sttdesc" v-if="item.srwp_payStt == '1'">已付款</div>
							<div class="sttdesc" v-if="item.srwp_payStt == '3'">确认完成</div>
							<div class="sttdesc" v-if="item.srwp_payStt == '0'">待付款</div>
							<div class="sttdesc" v-if="item.srwp_payStt == '4'">已取消</div>
							<div class="sttdesc" v-if="item.srwp_payStt == '5'">支付超时</div>
							<div class="flex-start">
							<div class="margr10">金额</div>
								<div class="amt_warp">
									<div class="amticon">¥</div>
									<div class="amtnum">{{item.stof_amt}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="aOrderInfo">
			<div>
				<div class="aoi_item">
					<div class="label">订单状态：</div>
					<div class="info-rcol">{{aOrderNo}}</div>
				</div>
				<div class="aoi_item">
					<div class="label">订单编号：</div>
					<div class="info-rcol">{{aOrderNo}}</div>
				</div>
				<div class="aoi_item">
					<div class="label">订单金额：</div>
					<div class="info-rcol">
						<label>{{aOrderAmt}}</label>
						<label>元</label>
					</div>
				</div>
				<div class="aoi_item">
					<div class="label">下单时间：</div>
					<div class="info-rcol">{{aOrderTime}}</div>
				</div>
			</div>
			<div>
				
			</div>
		</div>
		
	</div>	
</template>

<script>
	import {
		queryUrl,
		doPost,
		queryUrlWithUInfo,
		postWithUInfo
	} from '@/api/index';
	export default {
		data() {
			return {
				defalutImage:require('../../../assets/icon/noimg.jpg'),
				item:{},
				aOrderAmt:'',
				aOrderTime:'',
				aOrderNo:''
			}
		},
		created(){
			this.item = JSON.parse( this.$route.query.orderInfo);
			this.getWaitPayInfo(this.item.srwp_payNo);
		},
		methods:{
			async getWaitPayInfo(aOrderNo) {
				var vm = this;
				const initres = await queryUrlWithUInfo("/apis/getPayWaitReqInfo",{aOrderNo: aOrderNo});
				if (initres.ec == '0') {
					this.aOrderAmt = initres.aOrderAmt;
					this.aOrderTime = initres.aOrderTime;
					this.reqExpire = initres.reqExpire;
					this.aOrderNo = aOrderNo;
				}
			},
			showAvatarUrl(avatarUrl) {
				if (avatarUrl) {
					if (avatarUrl.indexOf('http') >= 0) {
						return avatarUrl;
					} else {
						return this.baseUrlpath + avatarUrl;
					}
				}
			},
			goBack() {
				this.$util.goBack();
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.container {
		width: 90%;
		margin: 0 auto;
	}
	.aOrderInfo {
		margin-top: 40px;
		.aoi_item {
			line-height: 40px;
			display: flex;
			.label {
				width: 184px;
				text-align: left;
			}
			.info-rcol {
				width: 278px;
				text-align: left;
			}
		}
	}
	.item-warp {
		margin-top: 40px;
		display: flex;
		.item-foot {
			margin-top: 20px;
			.btn {
				border: 1px solid #7b7777;
				padding: 0 12px;
				border-radius: 6px;
				font-size: 13px;
				line-height: 24px;
				cursor: pointer;
				margin: 0 6px;
			}
		}
		.item-head {
			margin-bottom: 10px;
			.avatar {
				width: 30px;
				height: 30px;
				overflow: hidden;
				border-radius: 50px;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.nickname {
				font-size: 12px;
				padding-left: 26px;
				width: 100px;
				text-align: left;
			}
		}
		.item-body {
			height: 100px;
			flex: 1;
			margin-left: 20px;
			.attach {
				width: 100px;
				height: 100px;
				overflow: hidden;
				border-radius: 10px;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.bodynail {
				height: 100%;
				flex: 1;	
				display: flex;
				justify-content: space-between;
				.body_center {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: space-around;
					margin-left: 20px;
				}
				.fontsmc {
					color: #C88854;
					font-size: 14px;
					text-align: left;
				}
				.conts {
					text-align: left;
					width: 100%;
					font-size: 14px;
				}
				.bodyfoot {
					font-size: 14px;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					align-items: flex-start;
					width: 200px;
					padding-left: 20px;
					.lb_warp {
						line-height: 28px;
						
					}
					.sttdesc {
						color: #C88854;
						margin-right: 20px;
						font-weight: bold;
					}
				}
			}
		}
	}
	.amt_warp {
		display: flex;
		justify-content: center;
		align-items: baseline;
		color: #F44336;
		.amtnum {
			font-size: 16px;
			font-weight: bold;
			margin-left: 2px;
		}
	}
</style>
